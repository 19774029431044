'use strict'

const m = require('mithril')
const reset = require('./reset.css')

const routes = require('./routes.js')
const Index = require('./pages/index')


m.route.prefix = ''

m.route(document.getElementById('root'), "/", Object.keys(routes).reduce((obj, key) => {
    obj[key] = eval(routes[key])
    return obj
}, {}))

