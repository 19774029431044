'use-strict'

const m = require('mithril')

const ResponsiveImage = {}

ResponsiveImage.view = vnode => {

	return m('picture', [
		m('source', {srcSet: vnode.attrs.src.srcSet, type:'image/webp', sizes:'(min-width: 1024px) 1024px, 100vw'}),
		m('img', {
			class: vnode.attrs.class,
			src: vnode.attrs.img.src,
			srcSet: vnode.attrs.img.srcSet,
			width: vnode.attrs.img.width,
			height: vnode.attrs.img.height,
			sizes: '(min-width: 1024px) 1024px, 100vw',
			loading: 'lazy',
		}),
	])
}

module.exports = ResponsiveImage