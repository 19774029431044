'use strict'
const m 		= require('mithril')
const pdfjsLib 	= require("pdfjs-dist")
const styles 	= require('./styles.scss')

// Setting worker path to worker bundle.
pdfjsLib.GlobalWorkerOptions.workerSrc = require("./pdf.worker.jsload").default

const PDFViwer = {}

PDFViwer.oninit = vnode => {

	vnode.state.page_num = 1
	vnode.state.page_count = 0

	let doc = null
	let pages = []
	let rendering = false
	vnode.state.canvas = null

	const getDocument = () => {
		if(doc) return Promise.resolve(doc)
		return pdfjsLib.getDocument(vnode.attrs.src).promise.then(pdfDocument => {
			doc = pdfDocument
			pages = new Array(doc.numPages).fill(null)
			vnode.state.page_count = doc.numPages
			m.redraw()
			return doc
		})
	}

	const getPage = num => {
		const idx = num - 1
		if(pages[idx]) return Promise.resolve(pages[idx])
		return getDocument().then(doc => {
			return doc.getPage(num).then(page => {
				pages[idx] = page
				return page
			})
		})
	}

	const renderPage = num => {

		vnode.state.page_num = num

		getPage(num).then(page => {

			if(vnode.state.page_num !== num || !vnode.state.canvas || rendering) return

			// Display page on the existing canvas with 100% scale.
			const viewport = page.getViewport({ scale: 5 })
			const canvas = vnode.state.canvas
			canvas.width = viewport.width
			canvas.height = viewport.height
			const ctx = canvas.getContext("2d")
			rendering = true

			return page.render({
				canvasContext: ctx,
				viewport,
			}).promise
		})
		.catch(console.error)
		.then(() => {
			rendering = false
		})
	}

	renderPage(vnode.state.page_num)



	vnode.state.setCanvas = _vnode => {
		vnode.state.canvas = _vnode.dom
		renderPage(vnode.state.page_num)
	}

	vnode.state.prevDisabled = () => vnode.state.page_num <= 1
	vnode.state.nextDisabled = () => vnode.state.page_num >= vnode.state.page_count

	vnode.state.prevPage = e => {
		if(vnode.state.prevDisabled()) return
		vnode.state.page_num--
		renderPage(vnode.state.page_num)
	}

	vnode.state.nextPage = e => {
		if(vnode.state.nextDisabled()) return
		vnode.state.page_num++
		renderPage(vnode.state.page_num)
	}

}


PDFViwer.view = vnode => {
	return m('div.page-container', {class: vnode.attrs.pdf_class}, [
		m('canvas.pdf-canvas', {
			oncreate: vnode.state.setCanvas,
		}),
		m('div.page-controls', {class: vnode.attrs.nav_class}, [
			m('div.page-control-item', {style: 'visibility: hidden;'}, [
				m('button.button', {onclick: vnode.state.prevPage, disabled: vnode.state.prevDisabled()}, m.trust('<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z"/></svg>')),
			]),
			m('div.page-control-item', [
				m('button.button', {onclick: vnode.state.prevPage, disabled: vnode.state.prevDisabled()}, '‹'),
				m('span', `${vnode.state.page_num} of ${vnode.state.page_count}`),
				m('button.button', {onclick: vnode.state.nextPage, disabled: vnode.state.nextDisabled()}, '›'),
			]),
			m('div.page-control-item', [
				m('a.button', {target: '_blank', href: vnode.attrs.src}, m.trust('<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z"/></svg>')),
			]),
		]),
	])
}


module.exports = PDFViwer
