'use strict'
const m 						= require('mithril')
const Navbar 					= require('../../components/navbar')
const Footer 					= require('../../components/footer')
const ResponsiveImage 			= require('../../components/responsive-image')
const PDFViewer 				= require('../../components/pdf-viewer')

const profile_photo 			= require('./augustbrenner.jpeg')
const profile_photo_webp 		= require('./augustbrenner.jpeg?format=webp')

const office_hours_image 		= require('./office-hours.jpg')
const office_hours_image_webp 	= require('./office-hours.jpg?format=webp')

const resume 					= require('./resume.pdf')

const styles 					= require('./styles.scss')


const Index = {}


Index.oninit = async vnode => {

}

Index.view = vnode => {

	return [
		m(Navbar),
		m('main.homepage-main', [
			m('section.intro', [
				m(ResponsiveImage, {src: profile_photo_webp, img: profile_photo, class: 'intro-image'}),
				m('div.intro-content', [
					m('h1.title', {style: 'font-size: 28px;'}, 'August Brenner'),
					m('p.paragraph', `Lifetime coder, who learned C++ and COBOL from his parents at the age of 7. Enjoys climbing, cycling, data structures, algorithms, board games, and surfing. Current and former co-founder of venture backed startups, with extensive front-end, back-end, and dev-ops experience. 12+ years building and managing scalable, developer-friendly web/mobile applications, analytics pipelines and software utilities. Broad-based technical background encompassing JavaScript, Java, Python, Ruby, Go, and C/C++ development. Strong product development acumen, with a long history of practical programming and an exceptional business mind. Experience consulting more than a dozen startups using hands on, data driven approaches to discover product-market fit and implement successful, quantifiable growth strategies. Hobbyist quant and angel investor.`),
				]),
			]),
			m('section.office-hours', [
				m('div.office-hours-content', [
					m('h1.title', 'Office Hours'),
					m('p.paragraph', [
						`Every Tuesday from 8:00am to 9:00am Pacific Time, I am available to anyone for office hours, which can be booked by clicking `,
						m('a', {onclick: e => Calendly.initPopupWidget({url: 'https://calendly.com/augustbrenner/office-hours'})}, 'here'),
						'.',
						m('br'),
						m('br'),
						m('b', 'What this is:'),
						m('p', [
							`A 30-minute one-on-one conversation where we can have a discussion about anything business or technology-related.`,
							m('br'),
							`These can be questions you have about hiring, management practices, technology selections, system design, and more. We can even go into depth to solve and code up solutions to specific problems that you have.`,
						]),
						m('br'),
						m('b', 'What this is not:'),
						m('p', [
							`This is not work. While I offer these office hours for free, I also expect that any discussions or problems we solve should be two-sided. I also expect these office hours to be used within reason. Lots of people need help or advice, so if the same person is filling up slots week after week, I will have to remove some or all of the bookings.`,
							m('br'),
							`This is also not an interview. While I do appreciate being considered for roles, I am currently engaged and not seeking new opportunities at the moment. If you would like to reach out to me about a topic that is not office-hours related, please do so by emailing me at `,
							m('a', {href:"mailto:augustbrenner@gmail.com"}, 'augustbrenner@gmail.com'),
							'.',
						]),
						m('br'),
						m('b', 'Why I offer this:'),
						m('p', [
							`The technology scene is fast-paced and ever-changing. I love solving problems and helping people solve theirs, and in doing so, I have found that it is one of the best ways to keep up to date on the latest trends in the industry.`,
						]),
					]),
				]),
				m('a', {onclick: e => Calendly.initPopupWidget({url: 'https://calendly.com/augustbrenner/office-hours'})}, [
					m(ResponsiveImage, {src: office_hours_image_webp, img: office_hours_image, class: 'office-hours-image'}),
				]),
				// m('div.calendly-inline-widget.offerings-calendar', {'data-url': "https://calendly.com/augustbrenner/office-hours",'style': "min-width:320px;height:750px;"}),
			]),
			m('section.curriculum-vitae', [
				m(PDFViewer, {src: resume.default, pdf_class: 'curriculum-vitae-pdf', nav_class: 'offerings-resume-nav'}),
				m('div.curriculum-vitae-content', [
					m('h1.title', 'Curriculum Vitae'),
					m('p.paragraph', [
						`I grew up in the suburbs of silicon valley. My mother was the chief database engineer at United Airlines, and my father was a prolific software contractor and quantitative analyst.`,
						m('br'),
						m('br'),
						`As far as I knew it, life was normal. We had dozens of computers, monitors, printers, network attached storage devices, and any other gizmo you'd find in a small data center. I would spend my childhood smashing old hard drives, and coding up rudimentary video games, and putting together Lego sets like it was my job.`,
						m('br'),
						m('br'),
						`For most of my life I took software development for granted. It was just a thing you did once to avoid doing it twice. I used it all the time, programming FIRST robots in high school, race car engines, UAVs, and factory control systems in college, but all the time, it never dawned on me how magical programming really was.`,
						m('br'),
						m('br'),
						`It wasn't until I got out into the real world and saw the countless ways that software has transformed, and is continuing to transform our world. To build a piece of software brings a tiny ephemeral spark of new life into the world, and as we continue to build upon the shoulders of giants, our software becomes more powerful, more sophisticated, and the life we bring into the world becomes more material along with it.`,
					]),
				]),
			]),
		]),
		m(Footer),
	]
}


module.exports = Index